import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import moment from "moment";
import { dxFeedSocketListen } from "../../appRedux/ducks/dxFeedSocket";
import "moment-timezone";
import wsLiteLight from "../../assets/images/wealth-series/ws-lite-light.png";
import wsLiteDark from "../../assets/images/wealth-series/ws-lite-dark.png";

import "../WealthSeriesOverview/WealthSeriesOverview.scss";
import "./LiteContainer.scss";
import LiteStockPicks from "./components/LiteStockPicks";
import WSTelegramCTA from "../WealthSeriesOverview/components/WSTelegramCTA/WSTelegramCTA";
import LiteLeaderboard from "./LiteLeaderboard/LiteLeaderboard";
import LiteLivestream from "./LiteLivestream/LiteLivestream";
import LiteMentorship from "./LiteMentorship/LiteMentorship";

const LiteContainer = ({ themeMode, stockRecs }) => {
  const hardHistory = useHistory();
  const { tab } = useParams();
  const [value, setValue] = useState(tab);
  const handleChange = useCallback((event, newValue) => setValue(newValue), []);

  const last_updated = useMemo(() => {
    if (stockRecs.length > 0) {
      const newestRecord = stockRecs.reduce((latest, current) => {
        return moment(latest.created_at).isAfter(moment(current.created_at))
          ? latest
          : current;
      });
      return moment(newestRecord.created_at)
        .tz("America/New_York")
        .format("dddd, MMM D, YYYY [at] h:mm A [(EST)]");
    }
    return moment()
      .tz("America/New_York")
      .format("dddd, MMM D, YYYY [at] h:mm A [(EST)]");
  }, [stockRecs]);

  // scroll to top smoothly on component rendering
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [tab]);

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  useEffect(() => {
    hardHistory.push(`/wealth-series-lite/${value}`);
  }, [value, hardHistory]);

  const goBookMentorship = () => {
    setValue("mentorship");
  };

  return (
    <div className="ws-lite-stock-picks">
      <div className="flex-between">
        <img
          className="ws-lite-logo"
          src={themeMode === "light" ? wsLiteLight : wsLiteDark}
        />
        <WSTelegramCTA className="ws-lite-telegram-cta" link="google.com" />
      </div>
      <div className="last-updated-text last-updated-text-mobile">
        Last updated: {last_updated}
      </div>
      <div className="ws-lite-overview__tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          className="fit-content ws-portfolio-ticker-info-tabs ws-lite-tabs"
        >
          <Tab label="AI Stock Picks" value={"ai-top-picks"} />
          <Tab label="Portfolio Leaderboard" value={"leaderboard"} />
          <Tab label="Livestream" value={"livestream"} />
          <Tab label="Mentorship" value={"mentorship"} />
        </Tabs>
        <div className="last-updated-text">Last updated: {last_updated}</div>
      </div>

      <WSTelegramCTA
        className="ws-lite-telegram-cta ws-lite-telegram-cta-mobile"
        link="google.com"
      />

      <div className="ws-lite-overview__content">
        {value === "ai-top-picks" && <LiteStockPicks />}
        {value === "leaderboard" && <LiteLeaderboard />}
        {value === "livestream" && (
          <LiteLivestream goBookMentorship={goBookMentorship} />
        )}
        {value === "mentorship" && <LiteMentorship />}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  stockRecs: state.liteStockRecs.stockRecommendations,
});

const dispatchToProps = (dispatch) => ({
  listen: (symbols, options, disconnect, risk) =>
    dispatch(dxFeedSocketListen(symbols, options, disconnect, risk)),
});

export default connect(stateToProps, dispatchToProps)(LiteContainer);
