import React, { useRef, useEffect, useState } from "react";
import ActionButtons from "./ActionButtons";
import "./LiteOnboarding.scss";
import LiteVideo1 from "../../assets/videos/01.webm";
import LiteVideo2 from "../../assets/videos/02.webm";
import LiteVideo3 from "../../assets/videos/03.webm";
import LiteVideo4 from "../../assets/videos/04.webm";
import LiteVideo5 from "../../assets/videos/05.webm";
import wsImage from "../../assets/images/wealth-series-lite/ws.svg";
import liteImage7 from "../../assets/images/wealth-series-lite/onboardingAssets/imageAssets/Image01-page07@2x.png";
import liteImage8 from "../../assets/images/wealth-series-lite/onboardingAssets/imageAssets/Image02-page08@2x.png";
import liteImage9 from "../../assets/images/wealth-series-lite/onboardingAssets/imageAssets/Image03-page09@2x.png";
import liteImage10 from "../../assets/images/wealth-series-lite/onboardingAssets/imageAssets/Image04-page10@2x.png";
import { useHistory } from "react-router-dom";

const titleLookup = {
  1: "Smart, Simple AI Stock Recommendations",
  2: "AI generated stock picks categorized by your trading goals",
  3: "Learn more about our stock picks by clicking on any ticker",
  4: "Happy with our stock pick? Add it to your portfolio",
  5: "Adjust the quantity of up to 10 stocks in your portfolio",
  6: "Adjust the quantity of up to 10 stocks in your portfolio",
  7: "Be sure to check out the other tabs within Wealth Series Lite",
  8: "Check out the best performing portfolios in the Leaderboard",
  9: "Every weekday, join our analyst in revealing the latest insights on the market",
  10: "Lastly, book 1-1 mentorship sessions that come with your membership",
};

const LiteOnboarding = () => {
  const videoRef = useRef(null);
  const [step, setStep] = useState(1);
  const history = useHistory();
  const contentLookup = {
    1: { type: "video", src: LiteVideo1 },
    2: { type: "video", src: LiteVideo2 },
    3: { type: "video", src: LiteVideo3 },
    4: { type: "video", src: LiteVideo4 },
    5: { type: "video", src: LiteVideo5 },
    6: { type: "video", src: LiteVideo5 },
    7: { type: "image", src: liteImage7 },
    8: { type: "image", src: liteImage8 },
    9: { type: "image", src: liteImage9 },
    10: { type: "image", src: liteImage10 },
  };
  const [content, setContent] = useState(contentLookup[step]);
  useEffect(() => {
    if (step === 10) {
      history.push("/wealth-series-lite/ai-top-picks");
    } else {
      setContent(contentLookup[step]);
    }
  }, [step]);
  useEffect(() => {
    if (content.type === "video") {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.playbackRate = 0.85;
      }
      let loopCount = 0;
      let timeoutId; // Declare a variable to hold the timeout ID for later clearance

      const handleVideoEnd = () => {
        if (step >= 2 && step <= 4) {
          // If on step 2, 3, or 4, automatically go to the next video
          timeoutId = setTimeout(
            () => setStep((currentStep) => currentStep + 1),
            1500,
          ); // Add a 1.5 second timeout before setting the next step
        } else if (step === 5) {
          // When step 5 ends, set step to 6
          timeoutId = setTimeout(() => setStep(6), 2000); // Add a 2 second timeout before setting step to 6
        } else {
          // Apply loop logic for step 1 or any step not intended to auto-advance
          loopCount += 1;
          if (loopCount < 3) {
            videoElement.play();
          }
        }
      };
      videoElement.addEventListener("ended", handleVideoEnd);

      return () => {
        videoElement.removeEventListener("ended", handleVideoEnd);
        if (timeoutId) {
          clearTimeout(timeoutId); // Clear the timeout to prevent side effects
        }
      };
    }
  }, [step, content?.type]); // Adjusted to include content.type in the dependency array

  return (
    <div className="lite-onboarding-container">
      <div className="video-container">
        {content.type === "video" ? (
          <video ref={videoRef} autoPlay muted src={content.src}></video>
        ) : (
          <img
            src={content.src}
            alt="Onboarding"
            className="onboarding-image"
          />
        )}
      </div>
      <div className={`main-container ${step >= 2 ? "step-2" : ""}`}>
        <div className="flex-col">
          <div className="header-container">
            <img
              loading="lazy"
              src={wsImage}
              alt="Company Logo"
              className="image-logo"
            />
            <div className="image-label">Lite</div>
          </div>
          {step >= 2 && <div className="title">{titleLookup[step]}</div>}
        </div>
        {step === 1 && (
          <div className="text-container">
            <div className="title">Smart, Simple AI Stock Recommendations</div>
            <div className="description">
              Powered by four distinct approaches, our AI highlights a range of
              stocks for every type of investor. Discover new opportunities at a
              glance and quickly find the strategy that matches your style.
            </div>
          </div>
        )}
        <ActionButtons step={step} setStep={setStep} />
      </div>
    </div>
  );
};

export default LiteOnboarding;
