import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { makeStyles } from "@mui/styles";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      textAlign: "center",
      color: "black",
    },
    "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
    "& .MuiOutlinedInput-root": {
      borderRadius: "var(--borderRadius-s, 4px)",
      background:
        "var(--_components-input-filled-enabledFill, rgba(0, 0, 0, 0.06))",
      border: "none",
      "&.Mui-focused fieldset": {
        border: "none",
      },
      "& fieldset": {
        border: "none", // Remove outline
      },
    },
  },
  removeIcon: {
    visibility: "hidden",
    cursor: "pointer",
    position: "absolute",
    left: "-14px",
    color: "red",
    transition: "visibility 0.4s",
    fontSize: "1.5rem",
  },
  tickerContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    transition: "transform 0.4s",
    "&:hover": {
      transform: "translateX(30px)",
    },
    "&:hover $removeIcon": {
      visibility: "visible",
    },
  },
  liteStockPicksPortfolioRow: {
    position: "relative",
  },
});

const PortfolioRow = ({
  portfolio,
  onQtyChange,
  onRemove,
  portfolios,
  userSettings,
  setUserSettings,
  setActiveTicker,
  handleSave,
}) => {
  const classes = useStyles();
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
      handleSave();
    }
  };

  const handleChange = (e) => {
    const newQty = parseInt(e.target.value, 10);
    // const totalQty = portfolios.reduce((acc, curr) => {
    //   return curr.ticker === portfolio.ticker ? acc + newQty : acc + curr.qty;
    // }, 0);
    onQtyChange(portfolio.ticker, newQty);
    if (!userSettings?.manualAdjust) {
      setUserSettings({
        ...userSettings,
        manualAdjust: true,
      });
    }
  };

  return (
    <div
      className={`${classes.liteStockPicksPortfolioRow} lite-stock-picks__portfolio_row`}
    >
      <div
        className={`${classes.tickerContainer} lite-stock-picks__portfolio_row_ticker`}
      >
        <RemoveCircleIcon
          className={classes.removeIcon}
          onClick={() => onRemove(portfolio.ticker)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
          }}
          onClick={setActiveTicker}
        >
          <FallbackTickerIcon
            src={`https://assets.tradealgo.com/stock-logos/${portfolio.ticker}.png`}
            ticker={portfolio.ticker}
            className="lite-stock-picks__portfolio_row_icon"
          />
          <div className="lite-stock-picks__portfolio_row_name">
            {portfolio.ticker}
          </div>
        </div>
      </div>
      <div className="lite-stock-picks__portfolio_row_price">
        ${portfolio.price}
      </div>
      <div className="lite-stock-picks__portfolio_row_change">
        <TextField
          type="number"
          value={portfolio.qty}
          inputProps={{ min: 0, max: 100, step: 1 }}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          variant="outlined"
          size="small"
          className={classes.input}
        />
      </div>
    </div>
  );
};

PortfolioRow.propTypes = {
  portfolio: PropTypes.shape({
    ticker: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired,
  }).isRequired,
  onQtyChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PortfolioRow;
