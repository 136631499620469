// components/SnackbarNotifier.js
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const SnackbarNotifier = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { message, options } = useSelector((state) => state.snackbar);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, {
        ...options,
        className: `custom-snackbar ${options.variant}`,
      });
    }
  }, [message, options, enqueueSnackbar]);

  return null; // This component doesn't render anything visible
};

export default SnackbarNotifier;
