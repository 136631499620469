import React from "react";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { Button } from "@mui/material";
import "./LiteStockPerformanceNews.scss";

const LiteStockPerformanceNews = ({
  activeTicker,
  ticker,
  news,
  isTickerInPortfolio,
  togglePortfolio,
}) => {
  const renderMarkdown = (markdownText) => {
    const htmlText = markdownText
      ?.replace(/(\*\*|__)(.*?)\1/g, "<strong>$2</strong>") // Bold
      ?.replace(/(\*|_)(.*?)\1/g, "<em>$2</em>") // Italic
      ?.replace(
        /\[([^\]]+)\]\(([^)]+)\)/g,
        '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>',
      ) // Links
      ?.replace(/\n/g, "<br />"); // Newline
    return { __html: htmlText };
  };
  return (
    <div className="lite-stock-performance-news">
      <div className="lite-stock-performance-news__header">
        <div className="lite-stock-performance-news__ticker_info">
          <FallbackTickerIcon
            src={`https://assets.tradealgo.com/stock-logos/${ticker}.png`}
            className="lite-stock-performance-news__ticker_icon"
            ticker={ticker}
          />
          <div className="lite-stock-performance-news__ticker_caption">
            Why {ticker}
          </div>
        </div>

        <Button
          className="lite-stock-performance-news__add_button"
          onClick={() => togglePortfolio(activeTicker)}
        >
          {isTickerInPortfolio(ticker)
            ? `Remove ${ticker} from portfolio`
            : `Add ${ticker} to portfolio`}
        </Button>
      </div>
      <div className="lite-stock-performance-news__content">
        <div
          className="lite-stock-performance-news__description"
          dangerouslySetInnerHTML={renderMarkdown(news)}
        />
      </div>
    </div>
  );
};

export default LiteStockPerformanceNews;
