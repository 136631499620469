import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import { widget } from "../../charting_library/charting_library.esm";
import Datafeed, { closeSocket, getUnsub } from "./datafeed";
import { useParams } from "react-router-dom";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import history from "./history";
import moment from "moment";
import MTZ from "moment-timezone";
import { useAuth } from "../../contexts/Auth";
import { apiURL, isInternalWS } from "../../appRedux/actions/helpers";
import FreeTierDatafeed from "./FreeTierDatafeed";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}
function MobileStockContainer({
  symbols,
  setSymbol,
  securityPrice,
  setLastPrice,
  userSettings,
}) {
  const { userData, canSeeInternal } = useAuth();
  const userId = userData?.userId;
  const search = useLocation().search;
  let resolution = userSettings?.defaultResolution || "15";
  const indicators = userSettings?.defaultIndicators || [];
  const candleType = userSettings?.candleType || "1";
  const token = new URLSearchParams(search).get("token");
  const theme = new URLSearchParams(search).get("theme");
  const settingsMemo = useMemo(() => {
    return `${resolution},${indicators},${candleType}`;
  }, [resolution, indicators, candleType]);
  //0 = private 1 = community
  const chartSaveMode = userSettings?.chartSaveMode || 0;
  const { symbol } = useParams();

  const [widgetRef, setWidgetRef] = useState({});
  const location = useLocation();
  const symbolRef = useRef(symbol);
  const unsubRef = useRef(getUnsub());
  useEffect(() => {
    unsubRef.current = getUnsub();
  }, [symbols.value, getUnsub()]);
  useEffect(() => {
    if (setLastPrice) {
      setLastPrice("");
    }
    getUnsub();
  }, [symbols.value]);
  useEffect(() => {
    return () => {
      setTimeout(() => {
        if (!isInternalWS) {
          Datafeed.unsubscribeBars("full-unsub");
        }
      }, 1200);
    };
  }, []);
  useEffect(() => {
    return () => {
      unsubRef?.current && unsubRef?.current();
    };
  }, [symbols.value]);
  useEffect(() => {
    return () => closeSocket();
  }, []);
  const disabled_features = useMemo(() => {
    return location.pathname.includes("guest")
      ? [
          "use_localstorage_for_settings",
          "display_market_status",
          "popup_hints",
          "header_screenshot",
          // "widget_logo",
          "header_indicators",
          "header_compare",
          "left_toolbar",
          "header_symbol_search",
          "header_settings",
          "header_fullscreen_button",
        ]
      : [
          "use_localstorage_for_settings",
          "display_market_status",
          "popup_hints",
          "header_screenshot",
          // "widget_logo",
        ];
  }, [location.pathname]);

  const urlResolution = new URLSearchParams(search).get("resolution");
  useEffect(() => {
    setWidgetRef({});
    const timeline = new URLSearchParams(search).get("timeline");
    if (urlResolution) {
      resolution = urlResolution;
    }
    const widgetOptions = {
      // debug: true,
      studies_overrides: {
        "williams alligator.jaw length": 13,
        "williams alligator.teeth length": 8,
        "williams alligator.lips length": 5,
        "williams alligator.jaw offset": 8,
        "williams alligator.teeth offset": 5,
        "williams alligator.lips offset": 3,
      },
      symbol: symbol,
      interval: resolution,
      datafeed: canSeeInternal ? Datafeed : FreeTierDatafeed,
      symbol_search_request_delay: 1000,
      container: "tv_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      time_frames: [
        { text: "5Y", resolution: "1D", description: "5 Years" },
        { text: "1Y", resolution: "1D", description: "1 Year" },
        { text: "6M", resolution: "1D", description: "6 Months" },
        { text: "3M", resolution: "1D", description: "3 Months" },
        { text: "1M", resolution: "1", description: "1 Month" },
        { text: "5D", resolution: "1", description: "5 Days" },
        { text: "1D", resolution: "1", description: "1 Day" },
      ].reverse(),
      header_widget_buttons_mode: "adaptive",
      disabled_features,
      enabled_features: [
        // "use_localstorage_for_settings",
        "side_toolbar_in_fullscreen_mode",
        "chart_style_hilo",
        // "extended_hours",
        "secondary_series_extend_time_scale",
        // "high_density_bars",
        "header_in_fullscreen_mode",
        "hide_last_na_study_output",
        "seconds_resolution",
      ],
      load_last_chart: false,
      fullscreen: false,
      autosize: true,
      width: "100%",
      timezone: "America/New_York",
      client_id: "patternscanner",
      theme: theme,
      user_id: userId,
      auto_save_delay: 10,
      charts_storage_url: apiURL,
      exchanges: [],
      session: "24x7",
      exchange: "US",
      // 1.3 = community , 1.1 = private
      charts_storage_api_version: chartSaveMode ? "1.3" : "1.1",
      overrides: {
        "mainSeriesProperties.showCountdown": true,
      },
      loading_screen: {
        backgroundColor: "#222222",
        foregroundColor: "#229712",
      },
    };
    // if logged in to test account allow saving of chart
    if (userId === "PzhoHfWuv6dxNChrwoCfffjh3of2") {
      widgetOptions.charts_storage_url = apiURL;
      widgetOptions.charts_storage_api_version = "1.1";
    }
    if (symbol) {
      const tvWidget = new widget(widgetOptions);
      tvWidget.onChartReady(() => {
        setWidgetRef(tvWidget);
        let chartStart = MTZ.utc().subtract(8, "hours").valueOf() / 1000;
        if (timeline) {
          if (timeline === "3M") {
            chartStart = moment().subtract(3, "months").utc().valueOf() / 1000;
          }
        }
        tvWidget
          .activeChart()
          .onIntervalChanged()
          .subscribe(null, function () {
            tvWidget
              .activeChart()
              .setVisibleRange(
                { from: chartStart, to: now },
                { percentRightMargin: 5 },
              );
          });
        const now = MTZ.utc().valueOf() / 1000;
        if (securityPrice?.date) {
          tvWidget
            .activeChart()
            .setVisibleRange(
              { from: chartStart, to: now },
              { percentRightMargin: 5 },
            )
            .then(() => {})
            .catch((err) => console.log(err));
        }

        tvWidget
          .chart()
          .onSymbolChanged()
          .subscribe(null, function (symbolData) {
            const type =
              symbolData.type === "fund"
                ? "mutual-fund"
                : symbolData.type === "stock"
                  ? "company"
                  : symbolData.type;
            //please do not remove this
            if (symbols.value !== symbolData.name) {
              history.push(`mobile-chart/${symbolData.name}?token=${token}`);
            }
            // sends first data request true on symbol change
            setSymbol({
              type,
              value: symbolData.name,
            });
            symbolRef.current = symbolData.name;
          });
      });
    }
  }, [
    securityPrice?.date,
    userId,
    chartSaveMode,
    canSeeInternal,
    theme,
    token,
  ]);
  useEffect(() => {
    if (Object.keys(widgetRef).length) {
      const chart = widgetRef.activeChart();
      const studies = chart.getAllStudies();
      // Check if volume study already exists
      const volumeStudy = studies.find(
        (study) => study.name.toLowerCase() === "volume",
      );
      // If volume study doesn't exist, remove all studies and create volume
      if (!volumeStudy) {
        chart.removeAllStudies();
        chart.createStudy("Volume");
      } else {
        studies.forEach((study) => {
          if (study.name.toLowerCase() !== "volume") {
            chart.removeEntity(study.id); // Correct method to remove a study by ID
          }
        });
      }
      // Set the chart type, resolution, and add other indicators
      if (urlResolution) {
        resolution = urlResolution;
      }
      chart.setChartType(Number(candleType));
      chart.setResolution(resolution);
      indicators?.forEach((indicator) => chart.createStudy(indicator));
    }
  }, [settingsMemo, urlResolution, widgetRef]);

  useEffect(() => {
    if (Object.keys(widgetRef).length && !!symbols.value) {
      if (symbols.type !== "crypto") {
        widgetRef.activeChart().setSymbol(symbols.value);
      }
    }
  }, [symbols.value]);
  return (
    <>
      {symbol && <div id="tv_chart_container" className={"TVChartContainer"} />}
    </>
  );
}
const stateToProps = (state) => ({
  symbols: state.symbol.symbol,
  marketStatus: state.companyData.companyData.marketState,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setSymbol: (symbol) => dispatch(symbolSetter(symbol)),
});

export default connect(stateToProps, dispatchToProps)(MobileStockContainer);
