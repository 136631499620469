import React, { useState } from "react";
import Button from "@mui/material/Button";

import "./LiteMentorship.scss";
import AnimatedMentorsDropdown from "../../common/AnimatedMentorsDropdown/AnimatedMentorsDropdown";
import { InlineWidget } from "react-calendly";
import { useAuth } from "../../../contexts/Auth";
import { connect } from "react-redux";
import { updateFieldsLoad } from "../../../appRedux/ducks/updateFields";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";

const LiteMentorship = ({
  themeMode,
  userSettings,
  setUserFields,
  setUserSettings,
}) => {
  const { userData } = useAuth();

  const mentors = [
    {
      id: 1,
      name: "Dane Glisek",
      calendlyLink:
        "https://calendly.com/analysts-4/options-mentorship-starter-liam",
    },
    {
      id: 2,
      name: "Mike Andrews",
      calendlyLink:
        "https://calendly.com/analysts-4/options-membership-starter",
    },
  ];

  const [selectedMentor, setSelectedMentor] = useState({});

  return (
    <div className="lite-mentorship">
      <div className="lite-mentorship-header">
        <div className="lite-mentorship-sessions-available">
          Sessions available:
        </div>
        <div className="lite-mentorship-sessions-number">
          <span>2</span> WS Lite Sessions
        </div>
      </div>

      <div className="lite-mentorship-content">
        <div className="lite-mentorship-content-left">
          <div className="lite-mentorship-content-left-body">
            <div className="lite-mentorship-content-banner">
              <div className="lite-mentorship-content-banner-label">
                Options Mentorship:
              </div>
              <div className="lite-mentorship-content-banner-title">
                Wealth Series Lite
              </div>
            </div>
            {selectedMentor.calendlyLink ? (
              <div
                className={`w-full ${themeMode === "light" ? "lite-light-calendar" : "lite-dark-calendar"}`}
              >
                <InlineWidget
                  prefill={{
                    email: userData?.email || "",
                    name:
                      (userData?.firstName || "") +
                      " " +
                      (userData?.lastName || ""),
                  }}
                  url={selectedMentor.calendlyLink}
                />
              </div>
            ) : (
              <>
                <div className="lite-mentorship-content-description">
                  Discover the power of the Wealth Series Lite portfolio through
                  hands-on learning and actionable insights. Participants will
                  gain an understanding of the portfolio's unique strategies and
                  learn to leverage the latest market trends to make informed
                  decisions.
                  <br />
                  <br />
                  This program provides practical guidance on options trading,
                  enabling users to explore advanced trading techniques while
                  staying informed with cutting-edge insights from the Wealth
                  Series Lite. Perfect for both beginners and experienced
                  traders, this session equips you with the tools to navigate
                  the dynamic world of options trading with confidence.
                </div>

                <Button
                  className="lite-mentorship-purchase-button"
                  variant="contained"
                >
                  Purchase More
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="lite-mentorship-content-right">
          <div className="lite-mentorship-mentors">
            <div className="lite-mentorship-mentors-title">
              Book a new session now:
            </div>
            <AnimatedMentorsDropdown
              mentors={mentors}
              selectedMentor={selectedMentor}
              setSelectedMentor={setSelectedMentor}
              comingSoon={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  setUserFields: (fields) => dispatch(updateFieldsLoad(fields)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(LiteMentorship);
