import React, { useEffect, useMemo, useState } from "react";

import "./LiteLeaderboard.scss";
import "./LiteLeaderboardRowMobile.scss";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import LiteLeaderboardRow from "./LiteLeaderboardRow";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { leaderboardLoad } from "../../../appRedux/ducks/leaderboard";
import { connect } from "react-redux";
import { userPortfoliosLoad } from "../../../appRedux/ducks/userPortfolio";
import { useAuth } from "../../../contexts/Auth";
import { findLongestName } from "../../homePage/DashboardHeader";

const LiteLeaderboard = ({
  loadLeaderboard,
  leaderboardData,
  leaderboardLoading,
  userPortfoliosLoad,
  userPortfolios,
}) => {
  const [resolution, setResolution] = useState("1d");
  const handleResolutionChange = (event, newResolution) => {
    setResolution(newResolution);
  };
  const { user, userData } = useAuth();
  const userName = useMemo(
    () => findLongestName(userData, user),
    [userData, user],
  );
  useEffect(() => {
    userPortfoliosLoad();
    loadLeaderboard();
  }, []);

  const userPortfolioId = useMemo(() => {
    if (!userPortfolios) return null;
    return userPortfolios[0]?.portfolio_id;
  }, [userPortfolios]);

  return (
    <div className="lite-leaderboard">
      <div className="lite-leaderboard__header">
        <div className="lite-leaderboard__header-title">
          <EmojiEventsIcon className="lite-leaderboard__header-icon" />
          Portfolio Leaderboard
        </div>

        <div className="lite-leaderboard__header-timestamp">
          <ToggleButtonGroup
            exclusive
            fullWidth
            aria-label="Leaderboard Period"
            className="ws-portfolio-resolutions"
            value={resolution}
            onChange={handleResolutionChange}
          >
            <ToggleButton className="ws-portfolio-resolution" value={"1d"}>
              1D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"7d"}>
              7D
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1m"}>
              1M
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"6m"}>
              6M
            </ToggleButton>
            <ToggleButton className="ws-portfolio-resolution" value={"1y"}>
              1Y
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div className="lite-leaderboard__content">
        <div className="lite-leaderboard__content-header">
          <div className="lite-leaderboard__collapse_button"></div>
          <div className="lite-leaderboard__pr">Portfolio Rank</div>
          <div className="lite-leaderboard__user">User</div>
          <div className="lite-leaderboard__focus">Focus</div>
          <div className="lite-leaderboard__stocks">Stocks Included</div>
          <div className="lite-leaderboard__performance">1D Performance</div>
          <div className="lite-leaderboard__action">Action</div>
        </div>

        <div className="lite-leaderboard__content-body">
          {!leaderboardLoading ? (
            <>
              {leaderboardData.slice(0, 10).map((portfolio, index) => (
                <LiteLeaderboardRow
                  portfolio={portfolio}
                  key={index}
                  rank={index + 1}
                  resolution={resolution}
                />
              ))}

              <div className="lite-leaderboard__content__splitter">
                <div className="lite-leaderboard__split_line" />
                <MoreHorizIcon />
                <div className="lite-leaderboard__split_line" />
              </div>

              <LiteLeaderboardRow
                id={userPortfolioId}
                portfolio={leaderboardData[leaderboardData.length - 1]}
                rank={leaderboardData.length}
                resolution={resolution}
                name={userName}
              />
            </>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  leaderboardData: state.leaderboard.portfolios,
  leaderboardLoading: state.leaderboard.loading,
  userPortfolios: state.userPortfolio.userPortfolios,
});

const dispatchToProps = (dispatch) => ({
  loadLeaderboard: () => dispatch(leaderboardLoad()),
  userPortfoliosLoad: () => dispatch(userPortfoliosLoad()),
});

export default connect(stateToProps, dispatchToProps)(LiteLeaderboard);
