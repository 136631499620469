import React, { useEffect, useMemo } from "react";
import Radar from "@dxfeed/radar";
import "./RadarContainer.scss";
import { connect } from "react-redux";
import { radarTokenLoad } from "../../appRedux/ducks/radarToken";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const LightTheme = React.lazy(() => import("./LightTheme"));
const DarkTheme = React.lazy(() => import("./DarkTheme"));

function RadarScannerContainer({ radarToken, getRadarToken }) {
  const search = useLocation().search;
  const theme = new URLSearchParams(search).get("theme");

  class RadarTokenManager {
    getToken() {
      return radarToken;
    }
    onExpiredToken() {
      // some actions on token expiration error, like request a new token
      // console.log('expired token')
    }
    onInvalidToken() {
      // some actions on invalid token error
      // console.log('invalid token')
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getRadarToken();
  }, []);

  const clientSettings = useMemo(() => {
    return {
      scannerUrl:
        "https://valsusa.dxfeed.com/radar-saas/prod/jstrading-edgx/api",
      auth: new RadarTokenManager(),
    };
  }, [radarToken]);

  // Please look through docs for the available props for features configuration
  return radarToken ? (
    <div style={{ height: "75%" }} className="radar container">
      <Helmet>
        <title> Scanner | TradeAlgo </title>
      </Helmet>
      <React.Suspense fallback={<></>}>
        {theme === "dark" ? <DarkTheme /> : <LightTheme />}
      </React.Suspense>
      <Radar
        clientSettings={clientSettings}
        allowAutoRefresh={true}
        allowTableExport={true}
        allowScanPresets={true}
        currencySign={"$"}
        title=""
        hideCopyright={true}
        //onSymbolClick={onSymbolClick}
      />
    </div>
  ) : (
    <div />
  );
}

const stateToProps = (state) => ({
  radarToken: state.radarToken.radarToken,
});

const dispatchToProps = (dispatch) => ({
  getRadarToken: () => dispatch(radarTokenLoad()),
});

export default connect(stateToProps, dispatchToProps)(RadarScannerContainer);
