export const mentorshipData = {
  starter: {
    title: "Starter Level",
    description: `
      The starter level sessions focus on introducing the fundamental concepts and principles of options trading. Participants will learn about market structure, basic trading strategies, and essential tools for success.
      <br/>
      <br/>
      Through hands-on exercises and engaging examples, newcomers will develop a strong foundation in the world of options trading, preparing them for more advanced topics.
    `,
    telegramLink: "https://t.me/+ikR0cMfCb0NiYTQx",
    mentors: [
      {
        id: 1,
        name: "Liam",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-starter-liam",
      },
      {
        id: 5,
        name: "Shane",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-starter-shane",
      },
      {
        id: 2,
        name: "Roman",
        calendlyLink:
          "https://calendly.com/analysts-4/options-membership-starter",
      },
      {
        id: 3,
        name: "Devin",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-starter-devin",
      },
      {
        id: 4,
        name: "Santiago",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-starter-santiago",
      },
      {
        id: 13,
        name: "Mike Andrews",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-starter-mike",
      },
    ],
  },
  intermediate: {
    title: "Intermediate Level",
    description: `
      Building on the knowledge acquired in the Starter level, intermediate sessions delve deeper into the intricacies of options trading. Participants will explore more sophisticated strategies and risk management techniques.
      <br/>
      <br/>
      With a mix of theory and practical application, these 1 on 1 sessions aim to sharpen options trading skills and enhance decision-making abilities for consistent returns.
    `,
    telegramLink: "https://t.me/+8L4VZ2JJ2RszNzAx",
    mentors: [
      {
        id: 5,
        name: "Shane",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-intermediate-shane",
      },
    ],
  },
  advanced: {
    title: "Advanced Level",
    description: `
      The Advanced level is designed for experienced traders who are looking to take their trading skills to an even higher level. Our team of expert mentors will provide you with personalized guidance as you navigate the complex and volatile market conditions.
      <br/>
      <br/> 
      With the support from our analysts, you will also have access to exclusive market analysis and research tools to help you stay ahead of the competition. 
    `,
    telegramLink: "https://t.me/+5IwxM3Vxzps1OTEx",
    mentors: [
      {
        id: 6,
        name: "Roman",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-advanced",
      },
      {
        id: 7,
        name: "Shane",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-advanced-shane",
      },
    ],
  },
  platinum: {
    title: "Platinum Level",
    description: `
      Personalized coaching and equipping the users with all the key resources and erudition required to comprehend the essential concepts and rules of options trading.
      <br/>
      <br/> 
      The program has 52 one-on-one meetings, along with complete access to the Platinum program with its own live stream and the sought-after Platinum Alerts.
    `,
    telegramLink: "https://t.me/+12TAYz7H9nhhMzk5",
    mentors: [
      {
        id: 1,
        name: "Liam",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-platinum-liam",
      },
      {
        id: 8,
        name: "Eric",
        calendlyLink:
          "https://calendly.com/analysts-4/platinum-vip-1-on-1-presentation",
      },
      {
        id: 9,
        name: "Dane",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-platinum-dane",
      },
      {
        id: 10,
        name: "Roman",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-platinum-roman",
      },
      {
        id: 11,
        name: "Shane",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-platinum-shane",
      },
    ],
  },
  emerald: {
    title: "Emerald Level",
    description: `
      The Emerald Level is designed to go beyond simple alerts, equipping users with all the tools and knowledge needed to access elite-level strategies.
      <br/>
      <br/>
      The program has 32 one-on-one meetings, along with access to the full Emerald program with its own live stream and the exclusive Emerald Income Alerts.
    `,
    telegramLink: "https://t.me/+WKhGeIKkzPE5YTZh",
    mentors: [
      {
        id: 12,
        name: "Brady",
        calendlyLink:
          "https://calendly.com/analysts-4/options-mentorship-emerald-brady",
      },
    ],
  },
};

export const sessionsAvailable = [
  {
    value: "starter",
    title: "Starter",
    description: "3",
  },
  {
    value: "intermediate",
    title: "Intermediate",
    description: "18",
  },
  {
    value: "advanced",
    title: "Advanced",
    description: "37",
  },
  {
    value: "platinum",
    title: "Platinum",
    description: "52",
  },
  {
    value: "emerald",
    title: "Emerald",
    description: "16",
  },
];
