import React from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";

import WSLiteLogo from "../../assets/images/wealth-series-lite/logo.png";
import WSLiteLogoDark from "../../assets/images/wealth-series-lite/logo-white.png";
import WSLiteSignupFrameLight from "../../assets/images/wealth-series-lite/frame.png";
import WSLiteSignupFrameDark from "../../assets/images/wealth-series-lite/frame-white.png";
import AssistantRoundedLight from "../../assets/images/wealth-series-lite/AssistantRounded.png";
import AssistantRoundedDark from "../../assets/images/wealth-series-lite/AssistantRounded-white.png";
import StreamRoundedLight from "../../assets/images/wealth-series-lite/StreamRounded.png";
import StreamRoundedDark from "../../assets/images/wealth-series-lite/StreamRounded-white.png";
import ChatRoundedLight from "../../assets/images/wealth-series-lite/ChatRounded.png";
import ChatRoundedDark from "../../assets/images/wealth-series-lite/ChatRounded-white.png";

import "./LiteSignUp.scss";

const featureData = [
  {
    imgLight: AssistantRoundedLight,
    imgDark: AssistantRoundedDark,
    title: "AI Driven Stock Picks",
    subtitle:
      "Get smarter insights instantly. Explore stock strategies crafted by advanced intelligence to match your unique goals.",
  },
  {
    imgLight: StreamRoundedLight,
    imgDark: StreamRoundedDark,
    title: "Interactive Livestreams",
    subtitle:
      "Engage in real-time discussions. Learn from live expert insights and dynamic stock analysis tailored to investors.",
  },
  {
    imgLight: ChatRoundedLight,
    imgDark: ChatRoundedDark,
    title: "Telegram Trade Alerts",
    subtitle:
      "Stay ahead of the curve with convenient biweekly updates, delivered on-the-go to keep you informed wherever you are.",
  },
];

const WealthSeriesLiteSignUp = ({ themeMode }) => {
  return (
    <div className="ws-lite-signup">
      <div className="ws-lite-signup__header">
        <div className="ws-lite-signup__header__left">
          <img
            src={themeMode === "light" ? WSLiteLogo : WSLiteLogoDark}
            alt="Wealth Series Lite"
            className="ws-lite-signup__logo"
          />
          <div className="ws-lite-signup__header__title">
            <div className="ws-lite-signup__title">Smart, Simple AI Stock</div>
            <div className="ws-lite-signup__title">Recommendations</div>
          </div>
          <div className="ws-lite-signup__header__subtitle">
            Powered by four distinct approaches, our AI highlights a range of
            stocks for every type of investor. Discover new opportunities at a
            glance and quickly find the strategy that matches your style.
          </div>
          <Button
            variant="contained"
            color="primary"
            className="ws-lite-signup__header__button"
          >
            Get started today at $750
            <span className="small-letters-em">/2yrs</span>
          </Button>
        </div>
        <div className="ws-lite-signup__header__right">
          <div className="ws-lite-signup__gradient-dot" />
          <img
            src={
              themeMode === "light"
                ? WSLiteSignupFrameLight
                : WSLiteSignupFrameDark
            }
            alt="Wealth Series Lite"
            className="ws-lite-signup__frame"
          />
        </div>
      </div>
      <div className="ws-lite-signup__content">
        {featureData.map(({ imgLight, imgDark, title, subtitle }) => (
          <div className="ws-lite-signup__item" key={title}>
            <img
              src={themeMode === "light" ? imgLight : imgDark}
              alt={title}
              className="ws-lite-signup__item__img"
            />
            <div className="ws-lite-signup__item_content">
              <div className="ws-lite-signup__item__title">{title}</div>
              <div className="ws-lite-signup__item__subtitle">{subtitle}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="ws-lite-signup__footer">
        This website provides AI-powered insights for informational purposes
        only. Please use at your own discretion, and consult a qualified
        financial advisor for personalized guidance.
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(WealthSeriesLiteSignUp);
