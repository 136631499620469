import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import WidgetLightIcon from "../../../assets/images/wealth-series-lite/stockPicks/widget-light.png";
import WidgetDarkIcon from "../../../assets/images/wealth-series-lite/stockPicks/widget-dark.png";
import StarLightIcon from "../../../assets/images/wealth-series-lite/stockPicks/star-light.png";
import StarDarkIcon from "../../../assets/images/wealth-series-lite/stockPicks/star-dark.png";
import SearchLightIcon from "../../../assets/images/wealth-series-lite/stockPicks/search-light.png";
import SearchDarkIcon from "../../../assets/images/wealth-series-lite/stockPicks/search-dark.png";
import MovingLightIcon from "../../../assets/images/wealth-series-lite/stockPicks/moving-light.png";
import MovingDarkIcon from "../../../assets/images/wealth-series-lite/stockPicks/moving-dark.png";
import PortfolioLightIcon from "../../../assets/images/wealth-series-lite/stockPicks/portfolio-light.png";
import PortfolioDarkIcon from "../../../assets/images/wealth-series-lite/stockPicks/portfolio-dark.png";
import WealthSeriesPortfolioTickerInfo from "../../WealthSeriesPortfolioPage/components/WealthSeriesPortfolioTickerInfo/WealthSeriesPortfolioTickerInfo";
import "./LiteStockPicks.scss";
import LiteStockPerformanceNews from "./LiteStockPerformanceNews";
import { stockRecommendationsLoad } from "../../../appRedux/ducks/liteStockRecs";
import { useSnackbar } from "notistack";
import PortfolioRow from "./PortfolioRow";
import {
  userPortfoliosCreate,
  userPortfoliosLoad,
  userPortfoliosUpdate,
} from "../../../appRedux/ducks/userPortfolio";
import { useMedia } from "react-media";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import LitePerformanceGraph from "./LitePerformanceGraph";
export const leaderboardLookup = {
  fundamentals_first: {
    title: "Fundamentals First",
    description: "Focused on financial health and company fundamentals.",
  },
  trend_tracker: {
    title: "Trend Tracker",
    description: "Identifies stocks trending in the market.",
  },
  sentiment_sentry: {
    title: "Sentiment Sentry",
    description: "Leverages market sentiment for stock picks.",
  },
  value_vs_growth: {
    title: "Value and Growth",
    description: "Balances between value investing and growth potential.",
  },
};
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const LiteStockPicks = ({
  themeMode,
  stockRecommendationsLoad,
  stockRecs,
  loading,
  userPortfoliosLoad,
  userPortfolios,
  userPortfoliosLoading,
  userPortfoliosCreate,
  createPortfolioResult,
  createLoading,
  userPortfoliosUpdate,
  updatePortfolioResult,
  updateLoading,
  performanceData,
  userSettings,
  setUserSettings,
}) => {
  const [portfolios, setPortfolios] = useState([]);
  const [initialPortfolios, setInitialPortfolios] = useState([]);
  const [isPerformanceShown, setIsPerformanceShown] = useState(false);
  const [error, setError] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [resolution, setResolution] = useState("1d");
  const [growth, setGrowth] = useState([]);
  const [activeTicker, setActiveTicker] = useState("");
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  // if this is set to true, then we need to not distribute evenly, rather add new tickers to the portfolio at 0%
  const manualAdjust = userSettings?.manualAdjust;
  useEffect(() => {
    if (userPortfolios && userPortfolios.length > 0) {
      setGrowth(userPortfolios[0].growth);
    }
  }, [userPortfolios]);
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      setError("");
    }
  }, [error, enqueueSnackbar]);
  useEffect(() => {
    if (stockRecs.length > 0) {
      setActiveTicker(stockRecs[0].stocks[0]);
      setIsPerformanceShown(false);
    }
  }, [stockRecs]);
  useEffect(() => {
    stockRecommendationsLoad();
    userPortfoliosLoad();
  }, [stockRecommendationsLoad, userPortfoliosLoad]);

  useEffect(() => {
    if (!!userPortfolios && userPortfolios.length > 0) {
      const loadedPortfolios = userPortfolios[0].allocations.map(
        (allocation) => ({
          ticker: allocation.ticker,
          qty: Math.round(allocation.ticker_weightage * 100),
          price: allocation.price,
        }),
      );
      setPortfolios(loadedPortfolios);
      setInitialPortfolios(loadedPortfolios);
    }
  }, [userPortfolios]);

  const distributeEvenly = useCallback(
    (newPortfolio, isAdding = false) => {
      if (manualAdjust) {
        if (isAdding) {
          // On add, keep the same previous quantities, only the new items, qty should be 0
          return newPortfolio;
        } else {
          // On remove, if manualAdjust is true, keep the old quantities
          return newPortfolio.map((portfolio) => {
            const existingPortfolio = portfolios.find(
              (p) => p.ticker === portfolio.ticker,
            );
            return existingPortfolio ? existingPortfolio : portfolio;
          });
        }
      } else {
        const totalStocks = newPortfolio.length;
        if (totalStocks > 10) {
          setError("Maximum of 10 stocks can be added.");
          return portfolios;
        }
        const qtyPerStock = Math.floor(100 / totalStocks);
        let leftover = 100 - qtyPerStock * totalStocks;
        return newPortfolio.map((portfolio, index) => {
          let qty = qtyPerStock;
          if (leftover > 0) {
            qty++;
            leftover--;
          }
          return { ...portfolio, qty };
        });
      }
    },
    [portfolios, manualAdjust],
  );

  const togglePortfolio = useCallback(
    (ticker) => {
      setPortfolios((prevPortfolios) => {
        const index = prevPortfolios.findIndex(
          (portfolio) => portfolio.ticker === ticker.ticker,
        );
        if (index !== -1) {
          // Removing a ticker, check for manualAdjust
          const newPortfolio = prevPortfolios.filter((_, i) => i !== index);
          return distributeEvenly(newPortfolio, false);
        } else {
          // Adding a new ticker, set qty to 0 for the new ticker if manualAdjust is true
          const newPortfolio = [
            ...prevPortfolios,
            { ticker: ticker.ticker, price: ticker.price, qty: 0 },
          ];
          return distributeEvenly(newPortfolio, true);
        }
      });
    },
    [distributeEvenly],
  );

  const isTickerInPortfolio = useCallback(
    (ticker) => {
      return portfolios.some((portfolio) => portfolio.ticker === ticker);
    },
    [portfolios],
  );

  const onRemove = useCallback(
    (ticker) => {
      setPortfolios((prevPortfolios) => {
        const newPortfolio = prevPortfolios.filter(
          (portfolio) => portfolio.ticker !== ticker,
        );
        // When removing, check for manualAdjust to decide if we should keep old quantities
        return distributeEvenly(newPortfolio, false);
      });
    },
    [distributeEvenly],
  );
  const themeDetails = useMemo(
    () => ({
      fundamentals_first:
        themeMode === "light" ? WidgetLightIcon : WidgetDarkIcon,
      trend_tracker: themeMode === "light" ? MovingLightIcon : MovingDarkIcon,
      sentiment_sentry:
        themeMode === "light" ? SearchLightIcon : SearchDarkIcon,
      value_vs_growth: themeMode === "light" ? StarLightIcon : StarDarkIcon,
    }),
    [themeMode],
  );

  const stockPicks = useMemo(
    () =>
      stockRecs.map((theme) => ({
        ...leaderboardLookup[theme.theme_name],
        icon: themeDetails[theme.theme_name],
        tickers: theme.stocks.map((stock) => ({
          ticker: stock.ticker,
          price: stock.price,
          rationale: stock.rationale,
        })),
      })),
    [themeMode, stockRecs, themeDetails],
  );

  const showPortfolioPerformance = () => {
    setIsPerformanceShown(true);
  };

  const handleSave = () => {
    const portfolioUpdateVar = {
      name: "User Portfolio",
      allocations: portfolios.map((p) => ({
        ticker: p.ticker,
        ticker_weightage: p.qty / 100,
      })),
    };

    if (userPortfolios && userPortfolios.length > 0) {
      // Use update endpoint if userPortfolios is not empty
      userPortfoliosUpdate(portfolioUpdateVar, userPortfolios[0].portfolio_id);
    } else {
      // Use create endpoint if userPortfolios is empty
      userPortfoliosCreate(portfolioUpdateVar);
    }
  };

  const handleCancel = () => {
    setPortfolios(initialPortfolios);
  };

  const hasChanges = useMemo(() => {
    if (portfolios.length !== initialPortfolios.length) return true;
    return portfolios.some((portfolio, index) => {
      const initial = initialPortfolios[index];
      return (
        portfolio.ticker !== initial.ticker || portfolio.qty !== initial.qty
      );
    });
  }, [portfolios, initialPortfolios]);

  const totalQtyPercentage = portfolios.reduce(
    (acc, curr) => acc + curr.qty,
    0,
  );

  const growthValues = {
    "1d": growth?.find((g) => g?.period === "1d")?.growth,
    "7d": growth?.find((g) => g?.period === "7d")?.growth,
    "1m": growth?.find((g) => g?.period === "1m")?.growth,
    itd: growth?.find((g) => g?.period === "itd")?.growth,
  };

  const formatGrowthValue = (value) => {
    const formattedValue = value?.toFixed(2);
    return value
      ? value > 0
        ? `+${formattedValue}%`
        : `${formattedValue}%`
      : "0.00%";
  };
  const portfolioId = userPortfolios[0]?.portfolio_id;
  const growthClass = (value) =>
    value > 0 ? "positive_color" : "negative_color";
  return (
    <div className="lite-stock-picks-page">
      <div className="lite-stock-picks__header">
        <div className="lite-stock-picks__title">
          Smart, Simple AI Stock Recommendations
        </div>
        <div className="lite-stock-picks__subtitle">
          Powered by four distinct approaches, our AI highlights a range of
          stocks for every type of investor. Discover new opportunities at a
          glance and quickly find the strategy that matches your style.
        </div>
      </div>

      <div className="lite-stock-picks">
        {stockPicks.map((stockPick, index) => (
          <div className="lite-stock-pick" key={index}>
            <div className="lite-stock-pick__header">
              <div className="lite-stock-pick__header_top">
                <div className="lite-stock-pick__header_title">
                  {stockPick.title}
                </div>
                <img
                  src={stockPick.icon}
                  alt="icon"
                  className="lite-stock-pick__icon"
                />
              </div>
              <div className="lite-stock-pick__description">
                {stockPick.description}
              </div>
            </div>
            <div className="lite-stock-pick__caption">Our picks</div>
            <div className="lite-stock-pick__tickers">
              {stockPick.tickers.map((ticker, tickerIndex) => (
                <div
                  className={`lite-stock-pick__ticker ${isTickerInPortfolio(ticker.ticker) ? "active" : ""}`}
                  key={tickerIndex}
                  onClick={() => {
                    setActiveTicker(ticker);
                    setIsPerformanceShown(false);
                  }}
                >
                  <div className="lite-stock-pick__ticker_info">
                    <FallbackTickerIcon
                      src={`https://assets.tradealgo.com/stock-logos/${ticker.ticker}.png`}
                      ticker={ticker.ticker}
                      className="lite-stock-pick__ticker_icon"
                    />
                    <div className="lite-stock-pick__ticker_name">
                      {ticker.ticker}
                    </div>
                  </div>
                  <div className="lite-stock-pick__ticker_price">
                    ${ticker.price}
                  </div>
                  <IconButton
                    className="lite-stock-pick__ticker_add"
                    size="small"
                    onClick={() => togglePortfolio(ticker)}
                  >
                    {isTickerInPortfolio(ticker.ticker) ? (
                      <PlaylistRemoveIcon className="lite-stock-pick__ticker_add" />
                    ) : (
                      <PlaylistAddIcon className="lite-stock-pick__ticker_add" />
                    )}
                  </IconButton>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="lite-stock-picks__content">
        <div className="lite-stock-picks__portfolio">
          <div className="lite-stock-picks__portfolio_header">
            <div className="lite-stock-picks__portfolio_header_left">
              <img
                src={
                  themeMode === "light" ? PortfolioLightIcon : PortfolioDarkIcon
                }
                alt="icon"
                className="lite-stock-picks__portfolio_icon"
              />
              <div>My Portfolio</div>
            </div>
            <div className="lite-stock-picks__portfolio_buttons">
              <Button
                variant="text"
                className="lite-stock-picks__portfolio_button"
                onClick={handleCancel}
                disabled={!hasChanges}
              >
                Cancel
              </Button>
              <Button
                variant="text"
                className="lite-stock-picks__portfolio_button"
                onClick={handleSave}
                disabled={!hasChanges}
              >
                Save
              </Button>
            </div>
          </div>
          <div className="lite-stock-picks__portfolio_th">
            <div className="lite-stock-picks__portfolio_th_ticker">Ticker</div>
            <div className="lite-stock-picks__portfolio_th_price">
              Current Price
            </div>
            <div className={`lite-stock-picks__portfolio_th_change`}>
              Qty (%) -{" "}
              <span
                className={totalQtyPercentage === 100 ? "full" : "not-full"}
              >
                {totalQtyPercentage}%
              </span>
            </div>
          </div>
          <div
            className={`lite-stock-picks__portfolio_content ${portfolios.length > 0 ? "flex-start" : ""}`}
          >
            {portfolios.length === 0 ? (
              <div className="lite-stock-picks__portfolio_empty">
                Add stock picks to start tracking your portfolio here.
              </div>
            ) : (
              portfolios.map((portfolio, index) => (
                <PortfolioRow
                  setActiveTicker={() => {
                    const rationale =
                      stockRecs
                        .flatMap((theme) => theme.stocks)
                        .find((stock) => stock.ticker === portfolio?.ticker)
                        ?.rationale || "";
                    const active = {
                      ...portfolio,
                      rationale,
                    };
                    setActiveTicker(active);
                    setIsPerformanceShown(false);
                  }}
                  setUserSettings={setUserSettings}
                  userSettings={userSettings}
                  portfolios={portfolios}
                  key={index}
                  portfolio={portfolio}
                  onQtyChange={(ticker, newQty) => {
                    const updatedPortfolios = portfolios.map((p) =>
                      p.ticker === ticker ? { ...p, qty: newQty } : p,
                    );
                    setPortfolios(updatedPortfolios);
                  }}
                  onRemove={onRemove}
                  handleSave={handleSave}
                />
              ))
            )}
          </div>
        </div>

        {isMobile ? (
          <div className="lite-stock-picks__performance">
            <div className="lite-stock-picks__performance_main">
              <div className="lite-stock-picks__performance_main_header">
                <div className="lite-stock-picks__performance_item">
                  <div className="lite-stock-picks__performance_item_title">
                    24H
                  </div>
                  <div
                    className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["1d"])}`}
                  >
                    {formatGrowthValue(growthValues["1d"])}
                  </div>
                </div>
                <div className="lite-stock-picks__performance_item">
                  <div className="lite-stock-picks__performance_item_title">
                    7D
                  </div>
                  <div
                    className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["7d"])}`}
                  >
                    {formatGrowthValue(growthValues["7d"])}
                  </div>
                </div>
                <div className="lite-stock-picks__performance_item">
                  <div className="lite-stock-picks__performance_item_title">
                    1M
                  </div>
                  <div
                    className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["1m"])}`}
                  >
                    {formatGrowthValue(growthValues["1m"])}
                  </div>
                </div>
                <div className="lite-stock-picks__performance_item">
                  <div className="lite-stock-picks__performance_item_title">
                    To Date
                  </div>
                  <div
                    className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["itd"])}`}
                  >
                    {formatGrowthValue(growthValues["itd"])}
                  </div>
                </div>
              </div>
              <div className="lite-stock-picks__performance_main_graph">
                <LitePerformanceGraph
                  setResolution={setResolution}
                  portfolioId={portfolioId}
                  resolution={resolution}
                />
              </div>
            </div>
            <Button
              variant="text"
              className="lite-stock-picks__performance_back_button"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={showPortfolioPerformance}
            >
              Back to view Portfolio Performance
            </Button>
            {activeTicker?.ticker && (
              <>
                {activeTicker?.rationale && (
                  <LiteStockPerformanceNews
                    isTickerInPortfolio={isTickerInPortfolio}
                    togglePortfolio={togglePortfolio}
                    ticker={activeTicker?.ticker}
                    activeTicker={activeTicker}
                    news={activeTicker?.rationale}
                  />
                )}
                <div className="lite-stock-picks__performance_ticker_info">
                  <WealthSeriesPortfolioTickerInfo
                    ticker={activeTicker?.ticker}
                    containerClassName=".lite-stock-picks__performance"
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="lite-stock-picks__performance">
            {isPerformanceShown ? (
              <div className="lite-stock-picks__performance_main">
                <div className="lite-stock-picks__performance_main_header">
                  <div className="lite-stock-picks__performance_item">
                    <div className="lite-stock-picks__performance_item_title">
                      24H
                    </div>
                    <div
                      className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["1d"])}`}
                    >
                      {formatGrowthValue(growthValues["1d"])}
                    </div>
                  </div>
                  <div className="lite-stock-picks__performance_item">
                    <div className="lite-stock-picks__performance_item_title">
                      7D
                    </div>
                    <div
                      className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["7d"])}`}
                    >
                      {formatGrowthValue(growthValues["7d"])}
                    </div>
                  </div>
                  <div className="lite-stock-picks__performance_item">
                    <div className="lite-stock-picks__performance_item_title">
                      1M
                    </div>
                    <div
                      className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["1m"])}`}
                    >
                      {formatGrowthValue(growthValues["1m"])}
                    </div>
                  </div>
                  <div className="lite-stock-picks__performance_item">
                    <div className="lite-stock-picks__performance_item_title">
                      To Date
                    </div>
                    <div
                      className={`lite-stock-picks__performance_item_change ${growthClass(growthValues["itd"])}`}
                    >
                      {formatGrowthValue(growthValues["itd"])}
                    </div>
                  </div>
                </div>
                <div className="lite-stock-picks__performance_main_graph">
                  <LitePerformanceGraph
                    setResolution={setResolution}
                    portfolioId={portfolioId}
                    resolution={resolution}
                  />
                </div>
              </div>
            ) : (
              <>
                <Button
                  variant="text"
                  className="lite-stock-picks__performance_back_button"
                  startIcon={<KeyboardBackspaceIcon />}
                  onClick={showPortfolioPerformance}
                >
                  Back to view Portfolio Performance
                </Button>

                {activeTicker?.ticker && (
                  <>
                    {activeTicker?.rationale && (
                      <LiteStockPerformanceNews
                        isTickerInPortfolio={isTickerInPortfolio}
                        togglePortfolio={togglePortfolio}
                        ticker={activeTicker?.ticker}
                        activeTicker={activeTicker}
                        news={activeTicker?.rationale}
                      />
                    )}
                    <div className="lite-stock-picks__performance_ticker_info">
                      <WealthSeriesPortfolioTickerInfo
                        ticker={activeTicker?.ticker}
                        containerClassName=".lite-stock-picks__performance"
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  stockRecs: state.liteStockRecs.stockRecommendations,
  loading: state.liteStockRecs.loading,
  userPortfolios: state.userPortfolio.userPortfolios,
  userPortfoliosLoading: state.userPortfolio.loading,
  createPortfolioResult: state.userPortfolio.createPortfolioResult,
  createLoading: state.userPortfolio.createLoading,
  updatePortfolioResult: state.userPortfolio.updatePortfolioResult,
  updateLoading: state.userPortfolio.updateLoading,
  userSettings: state.userSettings.userSettings,
});

const dispatchToProps = (dispatch) => ({
  stockRecommendationsLoad: () => dispatch(stockRecommendationsLoad()),
  userPortfoliosLoad: () => dispatch(userPortfoliosLoad()),
  userPortfoliosCreate: (portfolio) =>
    dispatch(userPortfoliosCreate(portfolio)),
  userPortfoliosUpdate: (portfolio, id) =>
    dispatch(userPortfoliosUpdate(portfolio, id)),
  setUserSettings: (settings, firstRender, fetch) =>
    dispatch(addUserSettingsLoad(settings, firstRender, fetch)),
});

export default connect(stateToProps, dispatchToProps)(LiteStockPicks);
