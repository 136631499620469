import React, { useEffect, useMemo, useState } from "react";
import { Button, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LinkIcon from "@mui/icons-material/Link";
import FallbackTickerIcon from "../../common/FallbackTickerIcon/FallbackTickerIcon";
import { leaderboardLookup } from "../components/LiteStockPicks";
import { connect } from "react-redux";
import { leaderboardDetailLoad } from "../../../appRedux/ducks/leaderboard";
import { useMedia } from "react-media";
import { userPortfoliosPerformanceLoad } from "../../../appRedux/ducks/userPortfolio";
import LitePerformanceGraph from "../components/LitePerformanceGraph";

const mediaQuery = {
  isMobile: "screen and (max-width: 768px)",
};

const LiteLeaderboardRow = ({
  portfolio,
  rank,
  loadLeaderboardDetail,
  portfolioDetail,
  resolution,
  id = null,
  name = null,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });
  const toggleRowView = () => {
    setIsExpanded(!isExpanded);
  };

  const growth = Number(portfolio?.total_growth?.toFixed(2));

  useEffect(() => {
    if (!portfolioDetail[id || portfolio?.portfolio_id] && isExpanded) {
      loadLeaderboardDetail(id || portfolio?.portfolio_id);
    }
  }, [id, portfolio?.portfolio_id, portfolioDetail, isExpanded]);
  const holdings = useMemo(
    () => (portfolioDetail[id || portfolio?.portfolio_id] || {})?.holdings,
    [portfolioDetail, id, portfolio?.portfolio_id],
  );

  return (
    <>
      {isMobile ? (
        <div className="lite-leaderboard__mobile_row">
          <div className="lite-leaderboard__mobile_row_basic">
            <div className="lite-leaderboard_mobile_row_basic_main_content">
              <div className="lite-leaderboard__mobile_row_header">
                <span>Portfolio Rank #{rank || "N/A"}</span>
                <Tooltip
                  title="Copy link to portfolio"
                  placement="left"
                  arrow
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <Button>
                    <LinkIcon />
                  </Button>
                </Tooltip>
              </div>
              <div className="lite-leaderboard__mobile_row_data">
                <div className="lite-leaderboard__mobile_row_data_row">
                  <div className="lite-leaderboard__mobile_row_data_row-label">
                    User
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row-value">
                    {name || portfolio?.user_name || "Anonymous"}
                  </div>
                </div>
                <div className="lite-leaderboard__mobile_row_data_row">
                  <div className="lite-leaderboard__mobile_row_data_row-label">
                    Focus
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row-value">
                    {leaderboardLookup[portfolio?.portfolio_focus]?.title ||
                      "N/A"}
                  </div>
                </div>
                <div className="lite-leaderboard__mobile_row_data_row">
                  <div className="lite-leaderboard__mobile_row_data_row-label">
                    Stocks Included
                  </div>
                  <div className="lite-leaderboard__mobile_row_data_row-value stocks_included">
                    {portfolio?.allocations?.map((allocation, index) => (
                      <span
                        key={index}
                        className="lite-leaderboard__stock_icon_wrapper"
                      >
                        <FallbackTickerIcon
                          src={`https://assets.tradealgo.com/stock-logos/${allocation.ticker}.png`}
                          className="lite-leaderboard__stock_icon"
                          ticker={allocation.ticker}
                        />
                      </span>
                    )) || "N/A"}
                  </div>
                </div>
                <div className="lite-leaderboard__mobile_row_data_row">
                  <div className="lite-leaderboard__mobile_row_data_row-label">
                    1D Performance
                  </div>
                  <div
                    className={`lite-leaderboard__mobile_row_data_row-value ${growth > 0 ? "color-green" : "color-red"}`}
                  >
                    {growth > 0 ? "+" : ""}
                    {growth}%
                  </div>
                </div>
              </div>
            </div>
            <div className="lite-leaderboard__mobile_row_basic_action">
              <Button onClick={toggleRowView}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
            </div>
          </div>

          <div
            className={`lite-leaderboard__expanded-content ${isExpanded ? "expanded" : ""}`}
          >
            <div className="lite-leaderboard__expanded-body">
              <div className="lite-leaderboard__expanded_chart mobile">
                <LitePerformanceGraph
                  fetch={isExpanded}
                  hideResolution
                  height={200}
                  portfolioId={portfolio?.portfolio_id}
                  resolution={resolution}
                />
              </div>

              <div className="lite-leaderboard__expanded_details">
                <div className="lite-leaderboard__expanded_details_header">
                  <div className="lite-leaderboard__expanded_details_ph">
                    Portfolio Holdings
                  </div>
                  <div className="lite-leaderboard__expanded_details_current_price">
                    Current Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_tracked_price">
                    Open Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_overall_performance">
                    Overall Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_period_performance">
                    Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_allocation">
                    Allocation
                  </div>
                </div>

                {holdings?.length > 0 ? (
                  holdings.map((data, index) => (
                    <div
                      className="lite-leaderboard__expanded_details_row color-black"
                      key={index}
                    >
                      <div className="lite-leaderboard__expanded_details_ph">
                        <FallbackTickerIcon
                          src={`https://assets.tradealgo.com/stock-logos/${data.ticker}.png`}
                          className="lite-leaderboard__expanded_stock_icon"
                          ticker={data.ticker}
                        />
                        <span className="font-14 font-bold">
                          {data?.companyName || "N/A"}
                        </span>
                      </div>
                      <div className="lite-leaderboard__expanded_details_current_price">
                        ${data.currentPrice || "N/A"}
                      </div>
                      <div className="lite-leaderboard__expanded_details_tracked_price">
                        ${data.trackedPrice || "N/A"}
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_overall_performance ${data.overallPerformance > 0 ? "color-green" : "color-red"}`}
                      >
                        {data.overallPerformance > 0 ? "+" : ""}
                        {data.overallPerformance?.toFixed(2) || "N/A"}%
                      </div>
                      <div
                        className={`lite-leaderboard__expanded_details_period_performance ${data.periodPerformance > 0 ? "color-green" : "color-red"}`}
                      >
                        {data.periodPerformance > 0 ? "+" : ""}
                        {data.performance?.toFixed(2) || "N/A"}%
                      </div>
                      <div className="lite-leaderboard__expanded_details_allocation">
                        {(data.ticker_weightage * 100)?.toFixed(2) || "N/A"}%
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No holdings data available.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="lite-leaderboard__row">
          <div className="lite-leaderboard__content-row">
            <div
              className="lite-leaderboard__collapse_button"
              onClick={toggleRowView}
            >
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <div className="lite-leaderboard__pr font-14 color-black">
              #{rank}
            </div>
            <div className="lite-leaderboard__user font-14">
              {name || portfolio?.user_name || "Anonymous"}
            </div>
            <div className="lite-leaderboard__focus font-14 color-black">
              {leaderboardLookup[portfolio?.portfolio_focus]?.title}
            </div>
            <div className="lite-leaderboard__stocks font-14">
              {portfolio?.allocations.map((allocation, index) => (
                <span
                  key={index}
                  className="lite-leaderboard__stock_icon_wrapper"
                >
                  <FallbackTickerIcon
                    src={`https://assets.tradealgo.com/stock-logos/${allocation.ticker}.png`}
                    className="lite-leaderboard__stock_icon"
                    ticker={allocation.ticker}
                  />
                </span>
              ))}
            </div>
            <div
              className={`lite-leaderboard__performance font-14 ${growth > 0 ? "color-green" : "color-red"}`}
            >
              {growth > 0 ? "+" : ""}
              {growth}%
            </div>
            <div className="lite-leaderboard__action font-14">
              <Tooltip title="Copy link to portfolio" placement="left">
                <Button>
                  <LinkIcon />
                </Button>
              </Tooltip>
            </div>
          </div>

          <div
            className={`lite-leaderboard__expanded-content ${isExpanded ? "expanded" : ""}`}
          >
            <div className="lite-leaderboard__expanded-body">
              <div className="lite-leaderboard__expanded_chart">
                <LitePerformanceGraph
                  fetch={isExpanded}
                  hideResolution
                  height={300}
                  portfolioId={portfolio?.portfolio_id}
                  resolution={resolution}
                />
              </div>
              <div className="lite-leaderboard__expanded_details">
                <div className="lite-leaderboard__expanded_details_header">
                  <div className="lite-leaderboard__expanded_details_ph">
                    Portfolio Holidings
                  </div>
                  <div className="lite-leaderboard__expanded_details_current_price">
                    Current Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_tracked_price">
                    Tracked Price
                  </div>
                  <div className="lite-leaderboard__expanded_details_overall_performance">
                    Overall Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_period_performance">
                    1D Performance
                  </div>
                  <div className="lite-leaderboard__expanded_details_allocation">
                    Allocation
                  </div>
                </div>

                {holdings?.map((data, index) => (
                  <div
                    className="lite-leaderboard__expanded_details_row color-black"
                    key={index}
                  >
                    <div className="lite-leaderboard__expanded_details_ph">
                      <FallbackTickerIcon
                        src={`https://assets.tradealgo.com/stock-logos/${data.ticker}.png`}
                        className="lite-leaderboard__expanded_stock_icon"
                        ticker={data.ticker}
                      />
                      <span className="font-14 font-bold">{data.ticker}</span>
                      <span className="font-14 lite-leaderboard-ticker-name">
                        {data?.companyName}
                      </span>
                    </div>
                    <div className="lite-leaderboard__expanded_details_current_price">
                      ${data.currentPrice}
                    </div>
                    <div className="lite-leaderboard__expanded_details_tracked_price">
                      ${data?.trackedPrice}
                    </div>
                    <div
                      className={`lite-leaderboard__expanded_details_overall_performance ${data?.overallPerformance > 0 ? "color-green" : "color-red"}`}
                    >
                      {data.overallPerformance > 0 ? "+" : ""}
                      {data.overallPerformance?.toFixed(2)}%
                    </div>
                    <div
                      className={`lite-leaderboard__expanded_details_period_performance ${data?.performance > 0 ? "color-green" : "color-red"}`}
                    >
                      {data?.performance > 0 ? "+" : ""}
                      {data.performance?.toFixed(2)}%
                    </div>
                    <div className="lite-leaderboard__expanded_details_allocation">
                      {(data.ticker_weightage * 100)?.toFixed(2)}%
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => ({
  portfolioDetail: state.leaderboard.portfolioDetail,
  portfolioDetailLoading: state.leaderboard.portfolioDetailLoading,
});

const dispatchToProps = (dispatch) => ({
  loadLeaderboardDetail: (id) => dispatch(leaderboardDetailLoad(id)),
  loadPerformance: (id, period) =>
    dispatch(userPortfoliosPerformanceLoad(id, period)),
});

export default connect(stateToProps, dispatchToProps)(LiteLeaderboardRow);
