import { all, put, takeEvery, fork, call } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";

// Action Types
export const LEADERBOARD_LOAD = "LEADERBOARD/LOAD";
export const LEADERBOARD_LOAD_SUCCESS = "LEADERBOARD/LOAD/SUCCESS";
export const LEADERBOARD_LOAD_FAILURE = "LEADERBOARD/LOAD/FAILURE";
export const LEADERBOARD_LOADING = "LEADERBOARD/LOADING";
// details
export const LEADERBOARD_DETAIL_LOAD = "LEADERBOARD/DETAIL/LOAD";
export const LEADERBOARD_DETAIL_LOAD_SUCCESS =
  "LEADERBOARD/DETAIL/LOAD/SUCCESS";
export const LEADERBOARD_DETAIL_LOAD_FAILURE =
  "LEADERBOARD/DETAIL/LOAD/FAILURE";
export const LEADERBOARD_DETAIL_LOADING = "LEADERBOARD/DETAIL/LOADING";

// Action Creators
export const leaderboardLoad = () => ({
  type: LEADERBOARD_LOAD,
});
export const leaderboardLoadSuccess = (portfolios) => ({
  type: LEADERBOARD_LOAD_SUCCESS,
  portfolios,
});
export const leaderboardLoadFailure = (error) => ({
  type: LEADERBOARD_LOAD_FAILURE,
  error,
});
export const leaderboardLoading = () => ({
  type: LEADERBOARD_LOADING,
});

// details
export const leaderboardDetailLoad = (portfolioId) => ({
  type: LEADERBOARD_DETAIL_LOAD,
  portfolioId,
});
export const leaderboardDetailLoadSuccess = (portfolioId, portfolioDetail) => ({
  type: LEADERBOARD_DETAIL_LOAD_SUCCESS,
  portfolioId,
  portfolioDetail,
});
export const leaderboardDetailLoadFailure = (error) => ({
  type: LEADERBOARD_DETAIL_LOAD_FAILURE,
  error,
});
export const leaderboardDetailLoading = (portfolioId) => ({
  type: LEADERBOARD_DETAIL_LOADING,
  portfolioId,
});

// Sagas
function* fetchLeaderboard() {
  try {
    yield put(leaderboardLoading());

    const { data } = yield call(
      axios.get,
      `${apiURL}/wealth-series-lite/leaderboard/portfolios`,
      { withCredentials: true },
    );
    yield put(leaderboardLoadSuccess(data?.leaderboard || []));
  } catch (error) {
    console.log({ error }, "err in leaderboard loader");
    yield put(leaderboardLoadFailure(error));
  }
}
// details
function* fetchLeaderboardDetail({ portfolioId }) {
  try {
    yield put(leaderboardDetailLoading(portfolioId));

    const { data } = yield call(
      axios.get,
      `${apiURL}/wealth-series-lite/leaderboard/portfolios/${portfolioId}`,
      { withCredentials: true },
    );
    yield put(leaderboardDetailLoadSuccess(portfolioId, data));
  } catch (error) {
    console.log({ error }, "err in leaderboard detail loader");
    yield put(leaderboardDetailLoadFailure(error));
  }
}

function* listenLeaderboardLoad() {
  yield takeEvery(LEADERBOARD_LOAD, fetchLeaderboard);
}

function* listenLeaderboardDetailLoad() {
  yield takeEvery(LEADERBOARD_DETAIL_LOAD, fetchLeaderboardDetail);
}

// Root Saga
export function* saga() {
  yield all([fork(listenLeaderboardLoad), fork(listenLeaderboardDetailLoad)]);
}

const INIT_STATE = {
  portfolios: [],
  portfolioDetail: {},
  loading: false,
  portfolioDetailLoading: {},
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEADERBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case LEADERBOARD_LOAD_SUCCESS:
      return {
        ...state,
        portfolios: action.portfolios,
        loading: false,
      };
    case LEADERBOARD_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case LEADERBOARD_DETAIL_LOADING:
      return {
        ...state,
        portfolioDetailLoading: {
          ...state.portfolioDetailLoading,
          [action.portfolioId]: true,
        },
      };
    case LEADERBOARD_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        portfolioDetail: {
          ...state.portfolioDetail,
          [action.portfolioId]: action.portfolioDetail,
        },
        portfolioDetailLoading: {
          ...state.portfolioDetailLoading,
          [action.portfolioId]: false,
        },
      };
    case LEADERBOARD_DETAIL_LOAD_FAILURE:
      return {
        ...state,
        portfolioDetailLoading: {
          ...state.portfolioDetailLoading,
          [action.portfolioId]: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
